const filters = {
    searchText: '',
    sortBy: 'byEdited'
};

// rtn filters via getFilters() so other files can use it
const getFilters = () => filters;

// updates parsed as object for searchText and sortBy
const setFilters = (updates) => {
    if (typeof updates.searchText === 'string') {
        filters.searchText = updates.searchText
    }

    if (typeof updates.sortBy === 'string') {
        filters.sortBy = updates.sortBy
    }
};


export { getFilters, setFilters }