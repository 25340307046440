import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

let notes = [];

// check for existing notes stored data in localStorage
const loadNotes = () => {
    const notesJSON = localStorage.getItem('notes'); // rtn null if no data, else the data

    try {
        return notesJSON ? JSON.parse(notesJSON) : [];
    } catch(e) {
        return [] // if problem with data, return empty array to enable user to continue
    }
};

// Save the notes to localStorage
const saveNotes = () => {
    localStorage.setItem('notes', JSON.stringify(notes)); // stored notes array to localStor
}

// expose notes from module
const getNotes = () => notes; // rtn notes to other files

// create a note
const createNote = () => {
    const id = uuidv4();
    const timestamp = moment().valueOf(); // create a timestamp for updatedAt
   
    notes.push({
        id,
        title: '',
        body: '',
        createdAt: timestamp,
        updatedAt: timestamp
    }); // add data to notes array 
    saveNotes() // save note to localStoragesaveNotes()

    return id; // required on index.js for location
}

// remove a note from the list
const removeNote = (id) => {
    const noteIndex = notes.findIndex((note) => note.id === id)
    //check a match was found, then remove it
    if (noteIndex > -1) {
        notes.splice(noteIndex, 1);
        saveNotes() // save data to localStorage if item is removed
    }
};

// sort notes by one of 3 ways
const sortNotes = (sortBy) => {
    if (sortBy === 'byEdited') {
        return notes.sort((a, b) => {
            if (a.updatedAt > b.updatedAt) {
                return -1; // a should come before b
            } else if(a.updatedAt < b.updatedAt) {
                return 1; // b should come before a
            } else {
                return 0; // both are same, leave as is
            }
        })
    } else if (sortBy == 'byCreated') {
        return notes.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
                return -1; // a should come before b
            } else if (a.createdAt < b.createdAt) {
                return 1; c
            } else {
                return 0; // both are same, leave as is
            }
        })
    } else if (sortBy === 'alphabetically') {
        return notes.sort((a, b) => {
           if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;  // a should come before b
           } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;  // a should come before b
           } else {
               return 0; // both are same, leave as is
           }
        })
    } else {
        return notes;
    } 

};

// update notes. updates is an object containing title or body data
const updateNote = (id, updates) => {
    const note = notes.find((note) => note.id === id);

    // if no note is found stop executing
    if (!note) {
        return;
    }

    if (typeof updates.title === 'string') {
        note.title = updates.title;
        note.updatedAt = moment().valueOf();
    }

    if (typeof updates.body === 'string') {
        note.body = updates.body;
        note.updatedAt = moment().valueOf();
    }

    saveNotes() // save data to localStorage
    return note; // note is required on edit.js for generateLastEdited
}

notes = loadNotes(); // set notes to return value of loadNotes()

export { getNotes, createNote, removeNote, sortNotes, updateNote };