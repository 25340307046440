import { createNote } from './notes';
import { setFilters } from './filters';
import { renderNotes } from './views';


// initial call
renderNotes();

// creates a new note
document.querySelector('#create-note').addEventListener('click', (e) => {
    const id = createNote(); // creates  note and return id for location
   location.assign(`/edit.html#${id}`);    
})

// filter notes title based on text in filter search box
document.querySelector('#search-text').addEventListener('input', (e) => {
    setFilters({
        searchText: e.target.value
    })
    renderNotes(); // calls renderNotes to clear and show new filters
})

// select box - changes filter to current selected filter
document.querySelector('#filter-by').addEventListener('change', (e) => {
    setFilters({
        sortBy:e.target.value
    })
    renderNotes();
})

// auto update the home page list when updated in edit page -keeps notes sync in multi tabs
window.addEventListener('storage', (e) => {
    if (e.key === 'notes') {
        renderNotes();
    }
})


