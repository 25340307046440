import moment from 'moment';
import { sortNotes, getNotes } from './notes';
import { getFilters } from './filters';


// Generate the DOM structure (list) for a note
const generateNoteDOM = (note) => {
    const noteEl = document.createElement('a');
    const textEl = document.createElement('p'); 
    const statusEl = document.createElement('p');

    // set up the note title text 
    if (note.title.length > 0) {
        textEl.textContent = note.title; // set note title 
    } else {
        textEl.textContent = 'Unnamed note'; // if no note title, set generic title
    }
    textEl.classList.add('list-item__title'); // adding styles class from css
    noteEl.appendChild(textEl);

    //set up link for a note
    noteEl.href = `/edit.html#${note.id}`; 
    noteEl.classList.add('list-item');

    // set up last edited
    statusEl.textContent = generateLastEdited(note.updatedAt);
    statusEl.classList.add('list-item__subtitle')
    noteEl.appendChild(statusEl);
    return noteEl;
};


// Render application notes
const renderNotes = () => {
    const notesEl = document.querySelector('#notes') // NB notesEl
    const filters = getFilters(); //from import
    const notes = sortNotes(filters.sortBy) // get sortNotes returned values from import    
    const filteredNotes = notes.filter((note) => note.title.toLowerCase().includes(filters.searchText.toLowerCase()))
    // clear prev content before displaying new ones below
    notesEl.innerHTML = '';
    
    // create new note and display, from filteredNotes
    if (filteredNotes.length > 0) {
        filteredNotes.forEach((note) => {
            const noteEl = generateNoteDOM(note); //refactoring      
            notesEl.appendChild(noteEl);
        })
    } else {
        const emptyMessage = document.createElement('p');
        emptyMessage.textContent = `No notes to show.`; // display message if no notes
        emptyMessage.classList.add('empty-message') // add a class name from css
        notesEl.appendChild(emptyMessage);
    }
    
};

// populate form on edit page with data if edit on another page
const initialiseEditPage = (noteId) => {
    const titleElement = document.querySelector('#note-title');
    const lastEditedElement = document.querySelector('#note-last-edited');
    const bodyElement = document.querySelector('#note-body');

    let notes = getNotes(); // load notes from array notes
    let note = notes.find((note) => note.id === noteId) // check if noteID matches a note from storage

    if (!note) {
        location.assign('/index.html'); // send them back to home page if id not found
    }

    titleElement.value = note.title;
    bodyElement.value = note.body;
    lastEditedElement.innerHTML = generateLastEdited(note.updatedAt);
};

//generate last edited message
const generateLastEdited = (timestamp) => `Last edited ${moment(timestamp).fromNow()}`;


export { generateNoteDOM, renderNotes, generateLastEdited, initialiseEditPage };